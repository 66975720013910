<template>
  <div class="outer-page">
    <x-table
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @add="add"
      @page-change="pageChange"
      rowKey="id"
    ></x-table>
    <Modal
      v-model="modal.show"
      :width="500"
      :title="modal.title"
      @on-visible-change="modalChange"
    >
      <Form ref="form" :model="form" :label-width="120" :rules="rules">
        <FormItem label="分类名称" prop="name">
          <Input clearable style="width: 260px" v-model="form.name"></Input>
        </FormItem>
        <FormItem label="单位" prop="unit">
          <Input clearable style="width: 260px" v-model="form.unit"></Input>
        </FormItem>
        <FormItem label="排序">
          <Input clearable style="width: 260px" v-model="form.sort"></Input>
        </FormItem>
        <FormItem label="备注">
          <Input
            clearable
            style="width: 260px"
            v-model="form.remarks"
            type="textarea"
          ></Input>
        </FormItem>
      </Form>
      <p slot="footer">
        <Button @click="() => (this.modal.show = false)">取消</Button>
        <Button type="primary" @click="submit">提交</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import CU from "@/common/util";
export default {
  components: {},
  name: "",
  data() {
    return {
      table: {
        columns: [
          {
            title: "类型名称",
            key: "name",
            minWidth: 160,
            tree: true,
          },
          {
            title: "排序",
            key: "sort",
          },
          {
            title: "备注",
            key: "remarks",
          },
          {
            title: "操作",
            width: 230,
            render: (h, { row }) => {
              return (
                <div>
                  <a style="margin-right:20px" on-click={() => this.edit(row)}>
                    编辑
                  </a>
                  <a style="margin-right:20px" on-click={() => this.add(row)}>
                    新增下级
                  </a>
                  <Poptip
                    confirm
                    transfer
                    title="确定删除吗?"
                    on-on-ok={() => this.delete(row.id)}
                  >
                    <a>删除</a>
                  </Poptip>
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      config: {
        filter: {
          add: {
            addBtnName: "新增顶级菜单",
            ca: "",
          },
        },
      },
      search_data: {},
      modal: {
        title: "",
        show: false,
        submitLoading: false,
      },
      form: {
        id: "",
        pid: "",
        name: "",
        sort: "",
        remarks: "",
        unit: "",
      },
      rules: {
        name: [{ required: true, message: "请填写类型名称" }],
        unit: [{ required: true, message: "请填写单位" }],
      },
    };
  },
  methods: {
    getList() {
      this.table.loading = true;
      this.$post(this.$api.CLASSFICATION.TREE)
        .then((res) => {
          this.table.data = CU.formatTreeTable(res, "children");
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    search(value) {
      this.search_data = value;
      this.getList();
    },
    add(row) {
      if (row) {
        this.form.pid = row.id;
      } else {
        this.form.pid = 0;
      }
      this.modal = {
        show: true,
        title: "添加分类",
        submitLoading: false,
      };
    },
    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.modal = {
        show: true,
        title: "编辑分类",
        submitLoading: false,
      };
    },
    delete(id) {
      this.$post(this.$api.CLASSFICATION.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功！");
        this.getList();
      });
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        let params = { ...this.form };
        let url;
        if (params.id) {
          url = this.$api.CLASSFICATION.UPDATE;
        } else {
          url = this.$api.CLASSFICATION.ADD;
        }
        if (!res) return;
        this.$post(url, params).then(() => {
          this.$Message.success(params.id ? "修改成功！" : "增加成功！");
          this.getList();
          this.modal.show = false;
        });
      });
    },
    pageChange(pageNo) {
      this.config.page.pageNo = pageNo;
      this.getList();
    },
    modalChange(visible) {
      if (visible) return;
      this.form = {
        id: "",
        pid: "",
        name: "",
        sort: "",
        remarks: "",
        unit: "",
      };
      this.$refs.form.resetFields();
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>